import { ServicesContentSection } from "./style";

const ServicesContentPages = ({ data }) => {
    return (
        <ServicesContentSection>
            <span className="desc">{data.content}</span>
        </ServicesContentSection>
    );
};

export default ServicesContentPages;
