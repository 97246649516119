import styled, { device } from "@theme/utils";
export const ServicesContentSection = styled.section`
    padding: 0px 0px 80px 0px;

    .desc {
        font-size: 14px !important;
        ${device.medium} {
            font-size: 18px !important;
        }
    }
`;
