import PropTypes from "prop-types";
import Layout from "@layout";
import SEO from "@components/seo";
import { graphql } from "gatsby";
import PageBreadcrumb from "../components/pagebreadcrumb";
import ServicsArea from "../container/services";
// import ServicesArea from "../container/home/services";
import TechnologyStack from "../container/technology-stack";
import OurSkills from "../container/about-us/skills";
import { normalizedData } from "@utils/functions";

const ServicesPages = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const content = normalizedData(data?.page.content || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO title="Services" pathname="/" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Services"
            />
            <ServicsArea
                data={{
                    ...content["services-section"],
                    items: data.allServices.nodes,
                }}
            />
            <TechnologyStack data={content["technology-stack-section"]} />
            <OurSkills data={content["our-skills-section"]} />
        </Layout>
    );
};
ServicesPages.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allServices: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query servicesPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        page(title: { eq: "servicesPage" }, pageType: { eq: innerpage }) {
            content {
                ...PageContentAll
                section_title {
                    ...SectionTitle
                }
                second_data {
                    content
                    description
                    header
                    sub_title
                }
            }
        }
        allServices(
            sort: { order: DESC, fields: id }
            filter: { is_featured: { eq: true } }
        ) {
            nodes {
                ...Services
            }
        }
        page(title: { eq: "servicesPage" }, pageType: { eq: innerpage }) {
            content {
                ...PageContentAll
                section_title {
                    ...SectionTitle
                }
                items {
                    headings {
                        level
                        content
                    }
                    experience
                    skill
                    list {
                        listItem
                    }
                }
                icons
                content {
                    text
                }
                skills {
                    description
                    items {
                        id
                        headings {
                            content
                            level
                        }
                        description
                    }
                    subheading
                    section_title {
                        showShortDec
                        showSubTitle
                        subtitle
                        title
                        dividerBg
                    }
                }
                text
            }
        }
    }
`;

export default ServicesPages;
